@tailwind base;
@tailwind components;
@tailwind utilities;

@import "normalize.css";
@import "animate.css";
@import "quill/dist/quill.core.css";
@import "quill/dist/quill.bubble.css";
@import "quill/dist/quill.snow.css";
@import "quill-mention/dist/quill.mention.min.css";
@import "swiper/css/bundle";
@import "remixicon/fonts/remixicon.css";
@import "ngx-toastr/toastr";

body .toast-container {
  [toast-component] {
    animation-name: lightSpeedInRight;
  }

  .ngx-toastr {
    @apply rounded-lg shadow-lg bg-base-200 bg-opacity-80 backdrop-blur-md 
         transition-transform duration-300 ease-in-out transform scale-100
         hover:backdrop-blur-lg hover:bg-opacity-100 hover:shadow-xl hover:scale-[1.01];

    &.toast-info {
      @apply bg-info bg-opacity-80 text-white;
    }

    &.toast-success {
      @apply bg-success bg-opacity-80 text-white;
    }

    &.toast-warning {
      @apply bg-warning bg-opacity-80 text-white;
    }

    &.toast-error {
      @apply bg-error bg-opacity-80 text-white;
    }
  }
}
