@forward 'imports';
@forward 'material';
@forward 'daisyui';
@forward 'ngx';

popper-content {
  position: absolute !important;
  z-index: 10000;
}

.ql-mention-list-container {
  @apply rounded-xl bg-white shadow-sm;
}

.ql-mention-list {
  @apply p-2 rounded-lg gap-1;

  .ql-mention-list-item {
    @apply p-2 rounded-lg text-base cursor-pointer shadow-none border-none;

    &:hover,
    &.selected {
      @apply bg-primary text-white;
    }
  }
}
