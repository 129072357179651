form,
.form {
  @apply flex flex-col flex-nowrap items-stretch gap-4 flex-1 basis-auto pb-[1px];
  overflow-y: overlay;

  > div:not(.form__control, .form__actions, .form__title, .form) {
    @apply flex flex-wrap items-stretch justify-between gap-4;

    > *:not(i) {
      flex: 1 0;
    }
  }

  > section:not(.form__control, .form__actions, .form__title, .form) {
    @apply flex flex-col flex-nowrap items-stretch gap-4;
  }

  a,
  p,
  h4,
  small,
  label,
  .form__title {
    margin-bottom: 0.3rem;
    padding: 0 0.3rem;
  }

  .form__title {
    @apply flex flex-nowrap items-center text-neutral text-opacity-70;
  }

  .form__control {
    @apply flex flex-col items-stretch justify-center;

    > label {
      font-size: 0.875rem;
      font-weight: 600;
    }

    > div:not(.control) {
      @apply flex flex-row flex-nowrap items-center p-2 gap-4;

      > * {
        flex: 1 0;
      }
    }

    &--h {
      @apply flex flex-row flex-nowrap items-center;
    }
  }

  .form__actions {
    @apply flex flex-nowrap items-center justify-end pt-2 mt-auto sticky gap-4;
    background-color: inherit;
    padding-bottom: 0.1rem;
    bottom: -1px;
  }
}
