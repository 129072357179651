.list {
  @apply flex flex-col flex-nowrap items-stretch justify-start content-start w-full h-auto p-0 m-0 appearance-none gap-2;

  li,
  &__item {
    @apply flex flex-row flex-nowrap items-center justify-start w-full h-auto gap-2;
    @apply bg-base-100 border border-neutral border-opacity-5 rounded shadow-md opacity-0 px-2 py-4 appearance-none;
    animation-name: fadeInDown;

    img {
      @apply w-12 h-12;
    }

    div {
      @apply p-4;
    }
  }
}
