@use 'sass:color' as color;

button,
.button {
  @apply w-[unset] h-[unset] inline-flex items-center justify-center cursor-pointer rounded outline-none transition-all gap-2 border-none p-3;

  &.btn-outline,
  &.icon {
    background-color: transparent !important;
  }

  &.btn-outline {
    @apply border-current border-solid border-[1px] text-current;
  }

  &.border-dashed {
    border-style: dashed !important;
  }

  &.toggle {
    padding: 0.5rem 1rem;
    span {
      display: none;
    }
  }

  &.compound {
    @apply w-auto h-auto flex flex-row flex-nowrap items-center justify-start;
  }

  &.icon {
    @apply w-auto h-auto  text-lg;
    i {
      position: relative;
      font-size: inherit;
      i {
        @apply absolute inset-0 text-[0.675em] top-[10%] left-[70%] z-10 flex items-center justify-center rounded-full;
        @apply w-[1em] h-[1em] bg-white;
      }
    }
  }

  &:not(.icon) i {
    @apply w-4 h-4 leading-4;
  }

  &:disabled {
    @apply cursor-not-allowed bg-neutral-200 text-neutral text-opacity-60;
  }
}

button.toggle span {
  @apply md:inline-flex;
}
