@use 'utilities' as utils;

@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html {
  @include utils.fluid-type(320px, 1440px, 14px, 16px);
}

body {
  font-family: 'Barlow', sans-serif;
}

h1 {
  font-weight: 800;
}

h2,
h3 {
  font-weight: 600;
}

h4,
h5 {
  font-weight: 500;
}

a {
  text-decoration: none;
}

i {
  @apply flex items-center justify-center relative;

  i {
    @apply absolute -bottom-1/4 -right-1/4;
  }
}
