select {
  appearance: none;
}

.input,
.select,
.textarea {
  @apply bg-base-100 border border-base-200;
  outline: none !important;
}

[type='checkbox'].toggle {
  @apply border border-base-200;
}

.btn {
  outline: none !important;

  &.btn-icon {
    @apply p-1 min-h-0 w-auto h-auto aspect-square;
  }

  &.btn-switch {
    @apply flex flex-col flex-nowrap items-center justify-center gap-0;

    i {
      @apply text-current text-lg leading-6;
    }

    [type='checkbox'].toggle {
      --tglbg: currentColor;
      --handleoffset: 0.5rem;
      height: 0.6rem;
      width: 1.2rem;
    }
  }
}

.btn-close {
  @apply order-last text-error cursor-pointer;
  background: transparent !important;

  &:is(i),
  i {
    @apply transition-transform duration-150 transform hover:rotate-90;
    font-size: 1.5rem !important;
  }
}

.text-title {
  @apply text-xl text-primary font-bold;
}

.text-force-ellipsis {
  @apply overflow-ellipsis whitespace-nowrap overflow-hidden max-w-full min-w-0;
}

.tab:is(input[type='radio']):after {
  @apply whitespace-nowrap;
}

.dropdown-content {
  @apply flex flex-col p-2 shadow-md menu z-10 bg-base-100 rounded-box w-auto flex-nowrap overflow-auto;
}

.divider-base-200 {
  &::after,
  &::before {
    @apply bg-base-200/60;
  }
}
