@import "@angular/cdk/overlay-prebuilt.css";

body {
  .mv-dialog-backdrop {
    @apply bg-neutral-400 bg-opacity-20 backdrop-blur-sm;

    background: linear-gradient(333deg,
        rgba(124, 124, 124, 0.2) 0%,
        rgba(222, 222, 222, 0.3) 11%,
        rgba(161, 161, 161, 0.2) 19%,
        rgba(228, 228, 228, 0.3) 35%,
        rgba(167, 165, 165, 0.2) 46%,
        rgba(209, 208, 208, 0.3) 53%,
        rgba(156, 156, 156, 0.2) 67%,
        rgba(227, 227, 227, 0.3) 81%,
        rgba(168, 168, 168, 0.2) 100%);
  }

  mv-dialog-wrapper {
    @apply flex max-w-[100svw] max-h-[100svh] bg-transparent rounded-xl;

    &:not(.bg-transparent) .mv-dialog-content {
      @apply bg-white;
    }

    .mv-dialog-content {
      @apply rounded-xl;
    }

    &[type='shy'] .mv-dialog-content {
      @apply rounded-none rounded-l-xl;
    }
  }

  .without-min {
    min-height: unset !important;
    min-width: unset !important;
  }
}