body {
  @apply relative bg-neutral-100 text-neutral w-[100svw] h-[100svh] flex flex-col overflow-hidden;

  * {
    box-sizing: border-box;
    animation-duration: 200ms;
    animation-fill-mode: forwards;
    animation-delay: calc(var(--index, 0) * 120ms);
    animation-timing-function: cubic-bezier(0.39, 0.58, 0.57, 1);
  }
}

.loader {
  @apply absolute flex flex-row items-center justify-center w-full h-full bg-base-100 bg-opacity-60 backdrop-blur-md z-[100];

  i {
    font-size: 2rem;
  }
}

.toast {
  @apply rounded-xl backdrop-blur-md min-w-[unset] font-medium;
}

.notify {
  position: relative;

  &::after {
    @apply absolute w-2 h-2 bg-success rounded-full shadow-md right-0 top-0;
    content: '';
  }
}

.splash {
  animation-name: fadeIn;
  animation-duration: 2s;
}

router-outlet {
  display: none;
}

.legend {
  @apply flex items-center text-xs;

  &::before {
    content: '';
    @apply mr-2 w-2 h-2 rounded-full bg-current;
  }
}

[texedit] {
  cursor:
    url('/assets/images/edit-line.svg') 8 8,
    text;
}
