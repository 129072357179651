[texedit] {
  input {
    background: transparent !important;
    width: 100% !important;
    padding: 0 !important;
    margin: 0 !important;
    min-height: inherit !important;
    height: inherit !important;
    font-size: inherit !important;
    color: inherit !important;
    opacity: inherit !important;
  }
}
