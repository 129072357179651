@use 'sass:color' as color;

.spacer {
  margin: auto;
}

.no-items {
  @apply flex flex-col flex-nowrap items-center justify-center text-neutral text-opacity-40
         text-center p-4 w-full text-base;
}
