[ng-reflect-copy] {
  cursor: copy;
}

*::-webkit-scrollbar {
  position: absolute;
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
}

*::-webkit-scrollbar-thumb {
  @apply bg-primary bg-opacity-50 rounded-lg outline-none;
}

*:hover::-webkit-scrollbar-thumb {
  @apply bg-primary bg-opacity-100;
}

.scroll-hidden::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
