@use '@angular/cdk' as cdk;
@use '@angular/material' as mat;
@use 'sass:map';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@include cdk.overlay();

$md-primary: (
  50: #e0eaea,
  100: #b3cbcc,
  200: #80a8aa,
  300: #4d8587,
  400: #266b6e,
  500: #005154,
  600: #004a4d,
  700: #004043,
  800: #00373a,
  900: #002729,
  A100: #63f3ff,
  A200: #30f0ff,
  A400: #00eafc,
  A700: #00d2e3,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$md-accent: (
  50: #fcf6ee,
  100: #f8ead5,
  200: #f3dcb9,
  300: #eecd9d,
  400: #eac388,
  500: #e6b873,
  600: #e3b16b,
  700: #dfa860,
  800: #dba056,
  900: #d59143,
  A100: #ffffff,
  A200: #fffaf5,
  A400: #ffe2c2,
  A700: #ffd5a8,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

@include mat.core();

$app-primary: mat.m2-define-palette($md-primary);
$app-accent: mat.m2-define-palette($md-accent, A200, A100, A400);
$app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $app-primary,
      accent: $app-accent
    ),
    typography: mat.m2-define-typography-config(),
    density: 0
  )
);

@include mat.core-theme($app-theme);
@include mat.all-component-themes($app-theme);

$palette: (
  colors: (
    base: #ffffff,
    neutral: #282b30,
    neutral-200: #e8f0f0,
    neutral-400: #798586,
    primary: #005154,
    secondary: #4d8587,
    accent: #ffdca8,
    error: #f8495a,
    warn: #fcf282,
    info: #0597e1,
    success: #1fa88d
  ),
  contrasts: (
    base: #282b30,
    neutral: #ffffff,
    neutral-200: #282b30,
    neutral-400: #ffffff,
    primary: #ffffff,
    secondary: #ffffff,
    accent: #ffffff,
    error: #ffffff,
    warn: #ffffff,
    info: #ffffff,
    success: #ffffff
  )
);

$colors: map.get($palette, 'colors');
$contrasts: map.get($palette, 'contrasts');

@each $name, $color in $colors {
  $contrast: map.get($contrasts, $name);

  button.mat-#{$name},
  button.mat-badge-#{$name} .mat-badge-content,
  .mat-bg-#{$name} {
    color: $contrast !important;

    &:not(.transparent) {
      background: $color !important;
    }

    &.transparent {
      background: rgba($color, 0.25) !important;
    }
  }

  .mat-text-#{$name},
  .mat-text-#{$name} {
    color: $color;
  }

  .mat-ind-#{$name}:before {
    background: $color !important;
  }
}

.cdk-overlay-connected-position-bounding-box {
  @apply flex flex-col flex-nowrap items-center justify-center;
}

.cdk-drag-preview {
  cursor: grabbing;
  .mat-ripple {
    display: none;
  }
}

.cdk-dialog-container {
  @apply overflow-hidden;
}

.mat-dialog-container {
  border-radius: 12px !important;
}

.mat-checkbox-background,
.mat-checkbox-frame {
  border-radius: 100% !important;
  border-width: 1px !important;
}
.mat-menu-panel {
  @apply border border-neutral-200 shadow-none rounded-lg overflow-hidden;
}
