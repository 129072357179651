.ribbon {
  position: relative;

  &::after {
    @apply absolute top-[-2em] right-[-2.675em];
    @apply flex items-center justify-center;
    @apply h-[2em] w-[9em] bg-primary;
    @apply text-[0.675rem] text-center text-white font-light;
    @apply transform origin-bottom-left rotate-45 shadow-md;

    content: 'preview';
    clip-path: polygon(2em 0, calc(100% - 2em) 0, 100% 100%, 100% 100%, 0 100%);
    z-index: 100;
  }
}
