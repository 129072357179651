.popper {
  @apply flex flex-col flex-nowrap items-stretch justify-start bg-base-100 shadow-lg p-1;

  &__arrow {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    position: absolute;

    border: {
      style: solid;
      width: 10px;
      color: transparent;
    }
  }

  &[x-placement^='top'] &__arrow {
    @apply -bottom-[10px] border-b-0 border-t-base-100 border-t-[10px];
  }

  &[x-placement^='bottom'] &__arrow {
    @apply -top-[10px] border-t-0 border-b-base-100 border-b-[10px];
  }
}
