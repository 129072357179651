.control {
  @apply flex flex-row items-center bg-base-100 rounded-lg outline-none pr-4 h-12 border border-base-200;
  min-height: 3rem;

  &:focus,
  &:focus-within {
    border: 1px solid var(--fallback-bc, oklch(var(--bc) / 0.2)) !important;
  }

  &__placeholder {
    @apply flex flex-row items-center mx-4 text-base;
    i {
      @apply ml-auto text-2xl;
    }
  }

  > input,
  > select {
    @apply bg-transparent outline-none border-none rounded-none p-0 flex-1 w-auto h-full;
  }

  &:-webkit-autofill,
  &:-webkit-autofill::first-line,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    border: none;
    outline: none;
    box-shadow: none;
    -webkit-text-fill-color: inherit;
    transition: background-color 5000s ease-in-out 0s;
  }
}
